select {
	padding: 10px 30px;
    width: 100%;
    height: 50px;
    margin-bottom: 15px;
    -webkit-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    transition: all 0.30s ease-in-out;
    color: #999;
    border: 1px solid #e4e4e4;
    -webkit-box-shadow: 1px 0px 2px rgba(0,0,0,0.05);
    -moz-box-shadow: 1px 0px 2px rgba(0,0,0,0.05);
    box-shadow: 1px 0px 2px rgba(0,0,0,0.05);
    outline: none;
    background-image: url(/dist/images/arrow.png);
    background-repeat: no-repeat;
    background-position: right 10px center;

}
input[type="text"], input[type="email"], input[type="tel"]{
  height:50px;
  width:100%;
  border-radius:3px;
  border:rgba(0,0,0,.3) 1px solid;
  box-sizing:border-box;
  padding:10px;
  padding-top: 0;
}

input[type="radio"], input[type="checkbox"] {
    margin-right: 10px;
}

input:not([type="submit"]), input, optgroup, select {
    padding: 12px 30px;
    -webkit-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    transition: all 0.30s ease-in-out;
    color: #333;
    border: 1px solid #e4e4e4;
    font-weight: 200;
    margin-bottom: 15px;
    outline: none;
    
}

input[type="date"] {
	width: 100%;
}

label {
    color: $gray-base;
    font-weight: 700;
    padding: 8px 0;
    display: block;
}

.fb-radio-inline label, .fb-checkbox-inline label {
    margin-right: 20px;
    display: inline-block;
    font-weight: 200;
     color: black;
}

.fb-radio-inline, .fb-checkbox-inline {
    display: inline-block;
}


textarea {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: rgba(0,0,0,0.3) 1px solid;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    height: 150px;
    max-width: none;
}