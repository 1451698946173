main {
	position: relative;
	aside {
		background-color: $gray-xlight;
		h2 {
			color: $color-primary; 
			padding-top: 0;
		}
	}
	h1 {
		color: $color-primary;
		padding-top: 20px;
	}
	h2 {
		color: $color-secondary;
		padding-top: 20px;
	}
	h3 {
		color: $gray-light;
	}
	.mobile-overlay {
		background-color: rgba(0,0,0,.5);
	    height: 100%;
	    width: 100%;
	    position: absolute;
	    z-index: 1;
	    display: none;
	}
	
	p {
		color: #878787;
		padding-bottom: 10px;
		padding-top: 10px;
	}
	.search-result {
		margin-bottom: 40px;
		padding-bottom: 40px;
		&:not(:last-of-type) {
			border-bottom: 1px solid $gray-light;
		}
	}
	ul {
		margin-left: 20px;
		padding-bottom: 10px;
		padding-top: 10px;
	}
}