button {
  cursor: pointer;
}
.btn {
	background-color: $color-secondary;
	border-radius: 3px;
	color: white;
	font-size: 20px;
	font-weight: bold;
	padding: 10px 20px;
	position: relative;
	&.hvr-radial-out {
	    display: inline-block;
	    vertical-align: middle;
	    -webkit-transform: perspective(1px) translateZ(0);
	    transform: perspective(1px) translateZ(0);
	    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	    position: relative;
	    overflow: hidden;
	    -webkit-transition-property: color;
	    transition-property: color;
	    -webkit-transition-duration: 0.3s;
	    transition-duration: 0.3s;
	    &:before {
		    content: "";
		    position: absolute;
		    z-index: -1;
		    top: 0;
		    left: 0;
		    right: 0;
		    bottom: 0;
		    background: white;
		    border-radius: 100%;
		    -webkit-transform: scale(0);
		    transform: scale(0);
		    -webkit-transition-property: transform;
		    transition-property: transform;
		    -webkit-transition-duration: 0.3s;
		    transition-duration: 0.3s;
		    -webkit-transition-timing-function: ease-out;
		    transition-timing-function: ease-out;
		}
	 	&:hover {
	 		color: $color-primary;
	 		text-decoration: none;
	 		&:before {
	    		transform: scale(2);
	    	}
	 	}
	}
	&-primary {
		background-color: $color-primary;
	}
}