header{
  background-color: white;
  @media (min-width: $small-width) {
    height: 60px; 
    position: relative;
    z-index: 1;
  }
  .box--primary {
    padding: 40px 40px 20px 40px; 
     @media (min-width: $small-width) {
      padding: 0;
    }
  }
  .btn--appointment {
    background-color: $color-primary;
    font-size: 19px;
    right: 0;
     @media (max-width: $small-width) {
      padding: 20px;
    }
  }
  .box--secondary {
    background-color: $color-secondary;
    font-size: 13px;
    line-height: 17px;
    padding-top: 5px;
    @media (min-width: $small-width) {
      font-size: 15px;
    }
   }
   .logo {
      height: 47px;
      margin-top: 5px;
      @media (max-width: $small-width) {
        margin-bottom: 10px;
      }
   }
  .primary-menu {
    font-weight: bold;
    @media (min-width: $medium-width) {
      height: 55px;
    }
    &__wrapper {
      background: rgba(white, 0.3);
      @extend %transition-base;
      @media (max-width: $medium-width) {
        left: -100%;
        background-color: white;
        z-index: 2;
        position: absolute;
        text-align: center;
        top: 60px;
        padding: 20px; 
      }
       &.is-open {
        left: 0;
        @extend %transition-base;
      } 
    } 
    > .nav > ul {
       @media (min-width: $medium-width) {
        display: flex;
        flex-direction: row;
      }
      > li {
        border-top: 2px solid transparent;

        &:hover {
          background: white;
          border-top: 2px solid $color-primary;
          .mega-menu__wrapper {
            display: block;
          }
        }
        &.color--secondary {
          &:hover {
            border-top: 2px solid $color-secondary;
          }
          a {
            &:before {
              content: "\f0fe";
              color: $color-primary;
              font-family: "FontAwesome";
              margin-right: 5px;  
            }
            color: $color-secondary; 
          }
        }
        > a {
          box-sizing: border-box;
          color: $color-primary;
          display: block;
          letter-spacing: 1px;
          line-height: 40px;
          padding: 6px 25px;
          text-decoration: none;
          text-transform: uppercase;
          @media (max-width: $medium-width) {
            color: $gray-base;
            font-size: 30px;
            border-top: 1px solid $gray-light;
          }
          @media (min-width: $large-width) {
            padding: 6px 16px;
            font-size: 14px;
          }
        }
      }
    }
    .sub-menu {
      display: none;
    }
  }
  .btn {
    border-radius: 0;
  }

  @keyframes reveal-mega-menu {
    0% {
      height: 0%;
    }

    100% {
      height: auto;
    }
  }

  @keyframes fadein-mega-menu {
    0% {
      opacity: 0;
      top: -5px;
    }

    100% {
      opacity: 1;
      top: 0;
    }
  } 

  .mega-menu {
    animation: fadein-mega-menu .5s ease-in-out;
    position: relative;

    &__wrapper {
      animation: reveal-mega-menu .25s ease-in-out;
      background: white;
      left: 0;
      min-height: 320px;
      width: 100%;
      z-index: 10; 
      @media (min-width: $medium-width) {
        display: none;
        position: absolute;
        box-shadow: 0 4px 4px -2px #777;
        padding: 35px 0 45px;
      }
    }
    &__content {
      
      box-sizing: border-box;
      border-right: 10px solid $color-secondary;
      padding-left: 33px;
      h2 {
        color: $color-primary;
        letter-spacing: 1px;
        margin-bottom: 10px;
        margin-top: 25px;
      }
      p {
        color: black;
        font-size: em(18px);
        font-weight: normal;
        line-height: em(28px, 18px);
        margin-bottom: 30px;
      }
      .learn-more {
        margin-bottom: 25px;
        &:after {
                
        }
        &:hover:after { 
           
        }
      }
    }
    &__subnav {
      .subnav {
        padding: 0;
        @media (min-width: $medium-width) {
          margin: 25px 0 25px 40px;
        }
        li {
          font-size: em(16px);
          line-height: em(20px, 16px);
          margin-bottom: 20px;

          a {
            color: $color-primary;
            text-decoration: none;
            text-transform: uppercase;

            &:hover {
              color: $color-secondary;
            }
          }
        }
      }
    }
  }

}