.breadcrumb {
	background-color: $color-secondary;
	color: white; 
	padding: 10px;
	li { 
		display: inline-block; 
		padding-right: 20px;
		a {
			font-weight: bold;
		}
	} 
}