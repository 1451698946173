.page {
	.features .circle {
		border-radius: 50%;
		border: 5px solid $color-secondary;
		font-size: 18px;
		height: 150px;
		width: 150px;
		margin: auto;
		position: relative;
		 p {
			transform: translateY(-50%);
			top: 50%;
			position: absolute;
			color: $color-primary;
			width: 100%;
			strong {
				display: block;
				font-size: 40px;
    			line-height: 30px;
			}
		}
	}
	&-template-tpl-a-propos {
		.partial--services {
			.box--image {
				height: 120px;
				background-size: auto 100px;
				&:hover {
			      background-size: auto 120px; 
			  	}
			}
		}
	}
	
}