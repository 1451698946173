.banner-slider {
  display: none;
  @media (min-width: $small-width) {
    display: block;
  }
  .cta {
    bottom: 10px; 
    position: absolute;
    left: 0;
    right: 0;
    text-align: center; 
    @media (min-width: $small-width) {
      bottom: 40px; 
    }
    @media (min-width: $medium-width) {
      bottom: 80px; 
    }
  }
  h1 {
    bottom: 100px;
    color: white;
    font-size: 20px;
    margin-bottom: 10px;
    padding: 0 40px; 
    text-shadow: 0px 0px 10px rgb(0,0,0);
    @media (min-width: $small-width) {
      font-size: 35px;
      line-height: 35px;
      margin-bottom: 20px;
    }
    @media (min-width: $medium-width) {
      font-size: 60px;
      line-height: 50px;
    }
  }
  img {
  	width: 100%;
  }
  .slick-dots {
    margin-left: 0;
  }
  .slick-list {
    width: 100%;
  }
}
.banner-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 276px;
  @media (min-width: $medium-width) {
    height: 600px;
  }
  &--small {
    background-size: contain;
    height: 300px;

  }
}