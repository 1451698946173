.partial {
	&--cta {
	  background-color: $color-primary;
	  padding-top: 30px;
	  padding-bottom: 30px;
	}
	&--services {
		& > div:nth-of-type(1) {
			.box--image {
				background-image: url('/dist/images/icon-orthophonie.png');
			}
		}
		& > div:nth-of-type(2) {
			.box--image {
				background-image: url('/dist/images/icon-psychologie.png');
			}
		}
		& > div:nth-of-type(3) {
			.box--image {
				background-image: url('/dist/images/icon-neuropsychologie.png');
			}
		}
		& > div:nth-of-type(4) {
			.box--image {
				background-image: url('/dist/images/icon-orthopedagogie.png');
			}
		}
		& > div:nth-of-type(5) {
			.box--image {
				background-image: url('/dist/images/icon-coaching-personnel.png');
			}
		}
		& > div:nth-of-type(6) {
			.box--image {
				background-image: url('/dist/images/icon-coaching-familial.png');
			}
		}
		& > div:nth-of-type(7) {
			.box--image {
				background-image: url('/dist/images/icon-coaching-conjugal.png');
			}
		}
		& > div:nth-of-type(8) {
			.box--image {
				background-image: url('/dist/images/icon-psychoeducation.png');
			}
		}
		& > div:nth-of-type(9) {
			.box--image {
				background-image: url('/dist/images/icon-sexologie.png');
			}
		}
		& > div:nth-of-type(10) {
			.box--image {
				background-image: url('/dist/images/icon-orientation.png');
			}
		}
		& > div:nth-of-type(11) {
			.box--image {
				background-image: url('/dist/images/icon-nutrition.png');
			}
		}
		& > div:nth-of-type(12) {
			.box--image {
				background-image: url('/dist/images/icon-osteopathie.png');
			}
		}
		& > div:nth-of-type(13) {
			.box--image {
				background-image: url('/dist/images/icon-travail-social.png');
			}
		}
	  	.box--image {
		    @extend %transition-base;
		    background-position: center;
		    background-repeat: no-repeat;
		  	background-size: contain;
		  	height: 150px;
		  	position: relative;
		  	@media (min-width: $small-width) {
		  	 	height: 170px;
		  	 	background-size: auto 130px;
		  	}
		  	&:hover {
		  		@extend %transition-base;
		  		cursor: pointer; 
		  		@media (min-width: $medium-width) {
			      background-size: auto 150px;
			  	} 
		  	}
		  	span {
		  	  	@extend %transition-base;
		  	  	height: 100%;
		  	  	left: 0;
		      	position: absolute;
		      	width: 100%;
		      	&:hover {
		        @extend %transition-base;
		      }
	  		}
	  	}
	  	h3 {
	  		@media (max-width: $small-width) {
	  			font-size: 15px;
	  			margin-top: 10px; 
	  		}
	  	}
	}
}