footer {
	background: black;
}
.pre-footer {
	background: $gray-xdark;
	.icon--building {
		height: 20px;
	}
	.nav > ul > li > a {
		font-weight: bold;
		color: white;
		display: block;
		margin-bottom: 10px;
	}
}